@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0px !important;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background: #000000;
  color: #ffffff;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 80px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 6px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #444444;
  }