.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 55px;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 50px 20px;
}

.hero-text {
  color: #ffffff;
  margin-bottom: 30px;
  margin-top: 50px;
  font-family: 'Good Times', sans-serif;

}

.hero-text h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: "nasalization", sans-serif;
}

.hero-text p {
  font-size: 1.5rem;
  margin-top: -12px;
  opacity: 0.9;
  font-family: "nasalization", sans-serif;
  position: relative;
}

.homepagebtn {
  padding: 15px 40px;
  border-radius: 0.8em;
  background: #ffffff;
  color: #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  font-family: inherit;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.homepagebtn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background: #000000;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}

.homepagebtn:hover {
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.homepagebtn:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.homepagebtn:active {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(45px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes spinAndZoom {
  0% {
    transform: rotate(0deg) scale(0.8); /* Start slightly smaller */
  }
  100% {
    transform: rotate(15deg) scale(1); /* Spin to 15 degrees instead of 45 degrees */
  }
}

@keyframes spinOnScroll {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg); /* Full spin on scroll */
  }
}

.animate-slide {
  opacity: 0;
  animation: slideFromBottom 0.5s ease-out forwards;
}

.animate-slide.title,
.animate-slide.description,
.animate-slide.homepagebtn {
  animation-delay: 0s;
}

.section-animate {
  opacity: 0;
  animation: slideFromBottom 1s ease-out forwards;
  animation-play-state: paused;
}

.section-animate.animate {
  animation-play-state: running;
}

.fade-effect {
  position: relative;
  width: 100%;
  height: 200px; /* Adjust height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.9) 100%);
  margin-top: -200px; /* Decrease this value to reduce the gap */
  z-index: 1; /* Ensure it appears above the background */
  filter: blur(5px); /* Add blur effect */
}

.hero-image {
  margin: 20px auto;
  animation: spinAndZoom 3s ease-out forwards;
  opacity: 0;
}

.hero-image.scrolling {
  animation: spinOnScroll 1s linear infinite;
  opacity: 1;
}

.title-word {
  margin-right: 10px; /* Adjust this value for desired spacing */
}

.animate-slide.title {
  animation-delay: 0.2s;
}

.animate-slide.description {
  animation: slideFromBottom 0.5s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
  display: block;
}

.animate-slide.homepagebtn {
  animation-delay: 0.6s;
}